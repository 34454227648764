<template>
    <div>
        <div class="row row-sm mg-t-20">
            <div class="col">
                <h2 style="color: white; text-decoration: underline;">TEAM STATS</h2>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <!-- OPEN IN SYSTEM -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Claims Open in System</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.open.toLocaleString()}}</h4>
                    </div>
                </div>
            </div>
            <!-- PENDING CLAIMS -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Pending Claims</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">
                            {{stats.pending.toLocaleString()}}
                        </h4>
                        <button v-show="$can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Pending')">Process</button>
                    </div>
                </div>
            </div>
            <!-- NEW CLAIMS -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">New Claims</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.untouched.toLocaleString()}}</h4>
                        <button v-show="stats.untouched <= 100 || $can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/New')">Process</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <!-- TAMPERED PLATES -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Tampered Plates</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.tampered.toLocaleString()}}</h4>
                        <button class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Tampered')">Process</button>
                    </div>
                </div>
            </div>
            <!-- LOCKED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Locked Claims</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.locked.toLocaleString()}}</h4>
                        <button class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Locked')">Process</button>
                    </div>
                </div>
            </div>
            <!-- DVLA RESPONDED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">DVLA Responded</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.dvla.toLocaleString()}}</h4>
                        <button v-show="stats.dvla <= 30 || $can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/DVLA')">Process</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <!-- PAID -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Paid Today</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.paid.toLocaleString()}}</h4>
                    </div>
                </div>
            </div>
            <!-- PROCESSED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Processed Today</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.processed.toLocaleString()}}</h4>
                    </div>
                </div>
            </div>
            <!-- NOTICES SENT -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Notices Sent</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.mailed.toLocaleString()}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <!-- POST FAILED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Post Failed</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.post_failed.toLocaleString()}}</h4>
                        <button class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Post%20Failed')">Process</button>
                    </div>
                </div>
            </div>
            <!-- FUEL DEBT COLLECTION -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">FUEL DEBT COLLECTION</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.fuel_debt_collection.toLocaleString()}}</h4>
                        <button v-show="stats.fuel_debt_collection <= 40 || $can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Fuel%20Debt%20Collection')">Process</button>
                    </div>
                </div>
            </div>
            <!-- PARKING DEBT COLLECTION -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">PARKING DEBT COLLECTION</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.parking_debt_collection.toLocaleString()}}</h4>
                        <button v-show="stats.parking_debt_collection <= 40 || $can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Parking%20Debt%20Collection')">Process</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$can('claims-manage-team')" class="row row-sm mg-t-20">
            <!-- NOT IN BATCH -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">NOT IN BATCH</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.notInBatch.toLocaleString()}}</h4>
                        <button v-show="$can('claims-manage-team')" class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Not%20in%20Batch')">Process</button>
                    </div>
                </div>
            </div>
            <!-- KEEPER APPEALED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">KEEPER APPEALED</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{stats.keeperAppealed.toLocaleString()}}</h4>
                        <button class="btn btn-sm btn-primary float-end" @click="$router.push('/globalclaims/Keeper%20Appealed')">Process</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <div class="col">
                <h2 style="color: white; text-decoration: underline;">
                    {{ personalStatsTitle }}
                    <div v-if="$can('claims-manage-team')" class="form-group" style="max-width: 300px; display: inline-block; float: right;">
                        <select @change="getPersonalStats" v-model="selectedUser" class="form-control form-control-dark select2" v-select2>
                            <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                        </select>
                    </div>
                </h2>
            </div>
        </div>
        <div class="row row-sm mg-t-20">
            <!-- PAID -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Paid Today - Claims I've Processed</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.paid.toLocaleString()}}</h4>
                    </div>
                </div>
            </div>
            <!-- PROCESSED -->
            <div class="col-sm-6 col-lg-4">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Processed By Me Today</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">
                            {{personalStats.processed.toLocaleString()}}
                        </h4>
                        <span class="tx-14" v-if="personalStats.processed_previous < personalStats.processed">
                            <i class="fas fa-arrow-up text-success"></i>
                            {{ personalStats.processed - personalStats.processed_previous }} more than {{ lastWorkingDay }} ({{ personalStats.processed_previous }})
                        </span>
                        <span class="tx-14" v-if="personalStats.processed_previous > personalStats.processed">
                            <i class="fas fa-arrow-down text-danger"></i>
                            {{ personalStats.processed_previous - personalStats.processed }} less than {{ lastWorkingDay }} ({{ personalStats.processed_previous }})
                        </span>
                    </div>
                </div>
            </div>
            <!-- OPEN TICKETS -->
            <div class="col-sm-6 col-lg-4" v-if="personalStats.open_tickets">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">My Open Tickets</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.open_tickets.toLocaleString()}}</h4>
                        <button v-if="myStats" class="btn btn-sm btn-primary float-end" @click="goToFreshdesk">Go To Freshdesk</button>
                    </div>
                </div>
            </div>

            <!-- REMINDERS -->
            <div class="col-sm-6 col-lg-4 mg-t-20" v-if="personalStats.reminders">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">My Reminders</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.reminders.toLocaleString()}}</h4>
                        <button v-if="myStats" class="btn btn-sm btn-primary float-end" @click="goToReminders">Go To Reminders</button>
                    </div>
                </div>
            </div>

            <!-- VIEW DURATION (DAY) -->
            <div class="col-sm-6 col-lg-4 mg-t-20" v-if="personalStats.view_duration?.day">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Average Time on Claims (Day)</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.view_duration.day}}</h4>
                    </div>
                </div>
            </div>
             <!-- VIEW DURATION (WEEK) -->
             <div class="col-sm-6 col-lg-4 mg-t-20" v-if="personalStats.view_duration?.week">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Average Time on Claims (Week)</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.view_duration.week}}</h4>
                    </div>
                </div>
            </div>
             <!-- VIEW DURATION (MONTH) -->
             <div class="col-sm-6 col-lg-4 mg-t-20" v-if="personalStats.view_duration?.month">
                <div class="card shadow-base bd-0 slight-glow" style="cursor: pointer;">
                    <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                        <h6 class="card-title tx-uppercase tx-white tx-12 mg-b-0">Average Time on Claims (Month)</h6>
                    </div>
                    <div class="card-body d-xs-flex justify-content-between align-items-center">
                        <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{personalStats.view_duration.month}}</h4>
                    </div>
                </div>
            </div>

        </div>
        <div class="row row-sm mg-t-20">
            <div class="col">
                <h2 style="color: white; text-decoration: underline;">
                    My Mistakes
                </h2>
            </div>
        </div>
        <div class="row row-sm mg-t-20">

            <div class="col">

                <div v-if="$can('claims-manage-team')" class="row mg-b-20">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-control-label tx-white tx-bold">Select User</label>
                            <select v-model="mistake.user_id" class="form-control form-control-dark select2" v-select2>
                                <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label tx-white tx-bold">What was the mistake?</label>
                            <textarea v-model="mistake.description" class="form-control form-control-dark" rows="3" placeholder="Enter mistake description"></textarea>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary float-right" :disabled="mistake.creating" @click="reportMistake">Report Mistake</button>
                        </div>
                    </div>
                </div>

                <pagination :data="mistakePagination" :show-count="true" @search="getClaimMistakes"></pagination>
                
                <div class="table-responsive">
                    <table class="table table-hover table-bordered table-striped">
                        <thead class="thead-primary">
                            <tr>
                                <th style="color: white;">Claim Reference</th>
                                <th style="color: white;">Reported By</th>
                                <th style="color: white;">Description</th>
                                <th style="color: white;">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="mistake in mistakes">
                                <td style="color: white;">{{mistake.claim_reference}}</td>
                                <td style="color: white;">{{mistake.reported_by_user}}</td>
                                <td style="color: white;">{{mistake.description}}</td>
                                <td style="color: white;">{{mistake.created_at}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <pagination :data="mistakePagination" :show-count="true" @search="getClaimMistakes"></pagination>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { DateTime } from 'luxon';
import Pagination from '../../components/Pagination.vue';

export default {
    name: 'Dashboard',
    components: {
        Pagination
    },
    data(){
        return {
            stats: {
                total: 9999,
                open: 9999,
                pending: 9999,
                paid: 9999,
                processed: 9999,
                untouched: 9999,
                tampered: 9999,
                locked: 9999,
                dvla: 9999,
                mailed: 9999,
                reminders: 9999,
                post_failed: 9999,
                fuel_debt_collection: 9999,
                parking_debt_collection: 9999,
                notInBatch: 9999,
                keeperAppealed: 9999,
            },
            personalStats: {
                paid: 0,
                processed: 0,
                processed_previous: 0,
                view_duration: {
                    day: "00:00:00",
                    week: "00:00:00",
                    month: "00:00:00"
                }
            },
            processors: [],
            selectedUser: this.$store.state.auth.user.id,
            mistakes: [],
            mistakePagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            mistake: {
                user_id: null,
                description: "",
                creating: false
            }
        }
    },
    mounted(){
        this.getStats();
        this.getPersonalStats();
        this.getClaimMistakes();
        if(this.$can('claims-manage-team')){
            this.loadClaimProcessors();
        }
    },
    methods: {
        getStats(){
            axios.get(`https://api.varsanpr.com/api/misc/counters`, { headers: authHeader() })
            .then(response => {
                if(!response.data.keeperAppealed){
                    response.data.keeperAppealed = 0;
                }
                this.stats = response.data;
            });
        },
        getPersonalStats(){
            axios.get(`https://api.varsanpr.com/api/misc/counters/personal?user_id=${this.selectedUser}`, { headers: authHeader() })
            .then(response => {
                this.personalStats = response.data;
            });
        },
        goToFreshdesk(){
            window.open('https://varstechnology.freshdesk.com/a/tickets/filters/search?orderBy=created_at&orderType=desc&page=7&q[]=status%3A%5B0%5D&ref=unresolved', '_blank');
        },
        goToReminders(){
            this.$router.push('/reminders');
        },
        loadClaimProcessors(){
            if(!this.$can('claims-process')){
                return;
            }
            axios.get(`https://api.varsanpr.com/api/users/processors`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.users = response.data.users.sort((a, b) => {
                    return a.email.localeCompare(b.email);
                });
                this.processors = response.data.users;
            })
            .catch(error => {
                this.$error("Failed loading processors!", error);
            });
        },
        getClaimMistakes(page = 1){
            axios.get(`https://api.varsanpr.com/api/users/${this.selectedUser}/mistakes?count=25&page=${page}`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.mistakes.forEach((m) => {
                    m.created_at = DateTime.fromSeconds(m.created_at).toLocaleString(DateTime.DATETIME_MED);
                });
                this.mistakes = response.data.mistakes;
                this.mistakePagination.currPage = response.data.pagination.page;
                this.mistakePagination.totalResults = response.data.pagination.total;
                this.mistakePagination.from = ((response.data.pagination.page - 1) * 25) + 1;
                this.mistakePagination.to = response.data.pagination.page * 25;
                this.mistakePagination.perPage = 25;
                this.mistakePagination.lastPage = Math.ceil(response.data.pagination.total / 25);
            })
            .catch(error => {
                this.$error("Failed loading mistakes!", error);
            });
        },
        reportMistake(){
            if(!this.$can('claims-manage-team')){
                return;
            }
            if(this.mistake.creating) return;
            this.mistake.creating = true;
            axios.post(`https://api.varsanpr.com/api/users/${this.mistake.user_id}/mistakes`, {
                description: this.mistake.description
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully logged mistake!");
                this.mistake.user_id = null;
                this.mistake.description = "";
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.mistake.creating = false;
            });
        },
    },
    computed: {
        lastWorkingDay: function(){
            let date = DateTime.local().minus({days: 1});
            if(date.weekday === 6){
                date = date.minus({days: 1});
            }else if(date.weekday === 7){
                date = date.minus({days: 2});
            }
            return date.weekdayLong;
        },
        personalStatsTitle: function(){
            if(this.selectedUser == this.$store.state.auth.user.id){
                return "MY PERSONAL STATS";
            }else{
                return "STATS FOR " + this.processors.filter(p => {
                    return p.id == this.selectedUser;
                })[0].email.toUpperCase();
            }
        },
        myStats: function(){
            return this.selectedUser == this.$store.state.auth.user.id;
        }
    }
}
</script>